import React from "react"
import Header from "../components/header"
import FisherMap from "../components/fisher-map"
import Footer from "../components/footer"
import Explore from "../components/explore"

import story from "../images/story.jpg"
//import news1 from "../images/news1.jpg"
//import news2 from "../images/news2.jpg"

const IndexPage = () => (
  <div id="indexPage">
    <Header/>

    <FisherMap/>

    <div className="content-pad">
      <div className="columns-2 big-margin-bottom">
        <div>
          <h2 style={{textAlign: 'left', margin: '0 0 25px 0'}}>Qui sommes-nous</h2>
          <p>
	        	FISH MED NET est un projet euro-méditerranéen visant à développer l'intégration et la diversification du secteur de la pêche en lui donnant une dimension nouvelle. Le projet est financé par l’UE dans le cadre du programme ENI CBC Med.
          </p>
          <p>
	        	En Méditerranée, la gestion durable des ressources de la pêche est un grand défi qui ne peut être reporté davantage. Aujourd'hui, la fragmentation de l’industrie freine considérablement la dynamisation de ce secteur économique clé, et l’intérêt des jeunes générations vis -à -vis de la pêche en est chute libre !
          </p>
          <p>
            Le projet FISH MED NET se concentre sur les entreprises du secteur de la pêche méditerranéenne en se focalisant sur des objectifs définis en quatre grandes thématiques, parmi lesquelles nous retrouvons le développement des entreprises et des TPE/PME.
          </p>
          <p>
		        Le Transfert technologique et l'Innovation et la Professionnalisation des Jeunes et des Femmes sont également des thèmes abordés par ce projet, dans le but de redynamiser le secteur de la pêche, de le rendre plus attractif auprès des jeunes, d’offrir des formations et faciliter l’accès à l’emploi grâce à des accompagnements professionnels.
          </p>
          <p>
	        	Grâce aux nouvelles activités commerciales, le projet aide les pêcheurs à devenir les gardiens de la mer, contribuant au SDG 14: Conserver et utiliser de manière durable les océans, les mers et les ressources marines pour le développement durable.
          </p>
        </div>
        <img src={story}/>
      </div>

      <h2>Comment postuler à FISH MED NET</h2>
      <p>
        FISH MED NET vise à atteindre le plus grand nombre possible de communautés de pêcheurs. Nous croyons que la coopération entre tous les pêcheurs impliqués est essentielle pour mener à bien une opération et que cela ne peut qu'être bénéfique si cette communauté prend soin les uns des autres. L'une des responsabilités du bureau de conseil est de fournir des informations sur la façon de rejoindre cette communauté et de devenir un modèle pour d'autres pêcheurs qui aspirent à mener leurs activités en pleine harmonie avec leur communauté locale, tout en respectant les objectifs de développement durable des Nations Unies. Pour plus d'informations sur l'endroit et la manière de contacter les bureaux de conseil, cliquez <a href="/howto/">ici</a>.
      </p>

	    <h2>Synergies avec les projets IEV CTF MED</h2>
      <p>
        L&#39;un des principes fondamentaux sur lesquels repose le projet FISH MED NET est la
        création d&#39;un environnement coopératif entre les différents acteurs, offrant la nécessaire
        visibilité des acteurs impliqués auprès des tiers. Tous ces efforts visent à un monde meilleur
        dans lequel vivre et, de même, le projet recherche des synergies avec d&#39;autres projets IEV
        CBC MED qui partagent des objectifs similaires. Sur cette page, vous pouvez voir les
        différents projets qui ont des synergies avec FISH MED NET et en savoir plus sur les ODD
        des Nations Unies partagés avec chaque projet.
	    </p>
      <p><a href="/cooperation">Plus d'informations</a></p>

    	<h2>Formation FishmedNet</h2>
	    <p>
        Dans le cadre des activités menées par le projet FISHMEDNET, la formation est un outil clé pour améliorer les compétences des entreprises et développer de nouvelles opportunités de diversification des activités de pêche.
      </p>
      <p><a href="/training">Plus d'informations</a></p>

    	<h2>Standard Package</h2>
	    <p>
	FISH MED NET explore également comment les pêcheurs peuvent devenir entrepreneurs en offrant des services à la collectivité.
      </p>
      <p><a href="/standard-package">Plus d'informations</a></p>

      <h2>Explorez parmi les dernières propositions</h2>
    </div>
    <Explore slug="index" />

    <Footer/>
  </div>
)

export default IndexPage
